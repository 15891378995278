import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Layout from "../components/layout/layout"
import ArtistGroup from "../components/festival/artist-group"
import LocationGroup from "../components/festival/location-group"
import PageTransition from "../components/transition/page"
import FestivalHeader from "../components/festival/festival-header"
import FestivalBackground from "../components/festival/festival-background"
import SEO from "../components/seo/seo"
import PartnerGroup from "../components/festival/partner-group"
import ThumbnailGroup from "../components/thumbnail/thumbnail-group"

class FestivalTemplate extends React.Component {

    constructor(props){
      super(props)
      this.pageTransition = React.createRef()
    }

    animate(){
      this.pageTransition.current.play()
    }

    render(){

        const locale = this.props.pageContext.locale
        const { festival } = this.props.data
        return(
          <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
            <SEO
              title={festival.seo.title}
              description={festival.seo.description}
              keywords={festival.seo.keywords && festival.seo.keywords.keywords}
              image={festival.seo.image.file.url}
            />
              <FestivalHeader wide={festival.theme.widescreenHeaderImage.file.url} portrait={festival.theme.portraitHeaderImage.file.url} animation={festival.theme.animation.file.url}>
                <div className={"container mx-auto pt-12 md:pt-0 lg:pt-12"}>
                  <img className={"mx-auto w-4/5 md:w-1/4 lg:w-1/3"} src={festival.theme.logo.file.url} alt="logo" />
                </div>
              </FestivalHeader>
              <FestivalBackground wide={festival.theme.widescreenBackground.file.url}>
                  <LocationGroup locations={festival.locations} locale={locale} />
                  <ArtistGroup 
                    artists={festival.artists} 
                    locale={locale} 
                    colorSecondary={festival.theme.colorSecondary} 
                    colorPrimary={festival.theme.colorPrimary} 
                  />
                  <ThumbnailGroup 
                    title={"Festival news"} 
                    items={festival.news}
                    locale={locale} 
                    href={`/${locale}/articles/`}
                  />
                  <PartnerGroup partners={festival.partners} locale={locale} />
              </FestivalBackground>
              <TransitionPortal>
                <PageTransition ref={this.pageTransition} />
              </TransitionPortal>
          </Layout>
        )

    }
}

export const pageQuery = graphql`
query FestivalPageQuery($slug: String! $locale: String!){
  festival: contentfulFestival(slug: {eq: $slug} node_locale:{eq:$locale}) {
    title
    seo {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    locations {
      ... on ContentfulLocation {
        title
        slug
        date
        tile{
          file{
            url
          }
        }
      }
    }
    artists {
      ... on ContentfulArtist {
        title
        slug
        thumbnail{
          file{
            url
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
    news {
      ... on ContentfulArticle {
        title
        slug
        short
        image {
          file {
            url
          }
        }
      }
    }
    partners{
      ...on ContentfulPartner{
        slug
        logo{
          file{
            url
          }
        }
      }
    }
    theme{
      ...on ContentfulTheme{
        logo{
          file{
            url
          }
        }
        animation{
          file{
            url
          }
        }
        portraitBackground{
          file{
            url
          }
        }
        portraitHeaderImage{
          file{
            url
          }
        }
        widescreenBackground{
          file{
            url
          }
        }
        widescreenHeaderImage{
          file{
            url
          }
        }
        colorPrimary
        colorSecondary
      }
    }
  }
}
`

export default FestivalTemplate
