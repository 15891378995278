import React from "react"
import { Link } from "gatsby"

import ArtistThumbnail from "../thumbnail/artist"
import Callout from "../festival/callout"
import SectionTitle from "../layout/section-title"

class ArtistGroup extends React.Component{

    render(){
        return(
            <div className={"container mx-auto py-4"}>
                <SectionTitle title={"The lineup"} position={"center"} />
                <div className={"flex flex-wrap -mt-12"}>
                {
                    this.props.artists.map((item, index) => {

                        let colorA = [0,3,4,7,8]
                        // let colorB = [0,1,2,5,6]
                        let color

                        if ( colorA.includes(index) ) {
                            color = this.props.colorPrimary
                            
                        }else{
                            color = this.props.colorSecondary
                        }

                        return(
                            <div className={"w-full sm:w-full md:w-1/2 px-4 md:px-6 lg:p-6"} key={index}>
                                <Link to={`/${this.props.locale}/artists/${item.slug}`}>
                                    <ArtistThumbnail
                                        title={item.title}
                                        image={item.thumbnail.file.url}
                                        color={color}
                                    />
                                </Link>
                            </div>
                        )
                    })
                }
                {
                  this.props.localAct
                    ?
                      <div className={"w-full sm:w-full md:w-1/2 px-4 md:px-6 lg:p-6"}>
                          <ArtistThumbnail
                              title={'local act'}
                              image={'https://images.ctfassets.net/8ipgrsmep6cq/6kBEDwsb2wH5hcBNaiD0lq/eb8312de1eb4a67f00a22c5da9fb30cb/local-act-thumb.jpg'}
                              color={this.props.colorPrimary}
                              background={this.props.colorSecondary}
                              cta={"coming soon"}
                          />
                      </div>
                    : null
                }
                </div>
                {
                  this.props.setTimes ? <Callout text={this.props.setTimes} /> : null
                }
            </div>
        )
    }

}

export default ArtistGroup
