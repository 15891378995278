import React from "react"

const Callout = ({text}) => {
    return(
        <div className={"text-center font-agrandir py-2 md:py-4 relative"}>
            <span className={"relative text-xs md:text-lg lg:text-xl"}>{text}</span>
            <div 
                className={"wave-horizontal-pattern block md:hidden absolute"}
                style={{
                    width:'60%',
                    height:'100%',
                    top:'0',
                    left:'20%',
                    zIndex:'-1'
                }}
            ></div>
            <div 
                className={"wave-horizontal-pattern hidden md:block absolute"} 
                style={{
                    width:'40%',
                    height:'68px',
                    top:'0',
                    left:'30%',
                    zIndex:'-1'
                }}>
                    
            </div>
        </div>
    )
}

export default Callout