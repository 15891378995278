import React from "react"
import PropTypes from "prop-types"

import "./thumbnail.scss"
import Button from "../button/button"

class ArtistThumbnail extends React.Component{

    render(){   

        return(
            <div className={"thumbnail w-full mb-12 relative"}>
                <div className={"image-holder overflow-hidden relative"} style={{height:`${(this.props.height || 320)}px`}}>
                    <div className={"image"} style={{
                            backgroundImage: `url(${this.props.image})`,
                            backgroundPosition:`center center`,
                            height:`${(this.props.height || 320)}px`
                        }}
                    >
                    </div>
                </div>
                <div className={"hover-background"} style={{height:`${(this.props.height || 320)}px `, backgroundColor:`#9cf2f7`}}></div>
                <div className={"artist-title -mt-8 text-center relative"}>
                    <p className={`title font-montserrat-extra-bold text-4xl md:text-4xl lg:text-4xl leading-none`} style={{color:this.props.color}}>{this.props.title}</p>
                </div>
                <div className={"text-center mt-4"}>
                    <Button label={this.props.cta} />
                </div>
            </div>
        )
    }

}

ArtistThumbnail.defaultProps = {
    cta:'explore artist'
}

ArtistThumbnail.propTypes = {
    cta: PropTypes.string
}

export default ArtistThumbnail