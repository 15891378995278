import React from "react"
import { Link } from "gatsby"
import _ from "lodash"
import moment from "moment"

import LocationTile from "./location-tile"
import SectionTitle from "../layout/section-title"

class LocationGroup extends React.Component {

    render(){

        let locationGroups = _.chunk(this.props.locations, 3)
        let locationGroupsMobile = _.chunk(this.props.locations, 2)

        return(
            <section className={"container mx-auto px-4"}>
                <SectionTitle title={"Locations"} position={"center"} />
                <div className={"flex flex-wrap hidden sm:hidden md:flex md:-mt-12"}>
                {
                    locationGroups.map((group, index) => {
                        return group.map((item, gindex) => {
                          return(
                            <div className={"w-1/2 md:w-1/3"} key={gindex}>
                              <Link to={`/${this.props.locale}/locations/${item.slug}`}>
                                <LocationTile
                                    title={item.title}
                                    day={moment.parseZone(item.date).format("ddd")}
                                    month={moment.parseZone(item.date).format("D MMMM")}
                                    file={item.tile.file.url}
                                    align={( index & 1 ) ? 'right' : ''}
                                />
                              </Link>
                            </div>
                          )
                        })
                      })
                }
                </div>
                <div className={"flex flex-wrap md:hidden -mt-12"}>
                {
                    locationGroupsMobile.map((group, index) => {
                        return group.map((item, gindex) => {
                          return(
                            <div className={`w-1/2 mx-auto`} key={gindex}>
                              <Link to={`/${this.props.locale}/locations/${item.slug}`}>
                                <LocationTile
                                    title={item.title}
                                    day={moment.parseZone(item.date).format("ddd")}
                                    month={moment.parseZone(item.date).format("MMM Do")}
                                    file={item.tile.file.url}
                                    align={"center"}
                                />
                              </Link>
                            </div>
                          )
                        })
                      })
                }
                </div>
            </section>
        )
    }

}

export default LocationGroup
